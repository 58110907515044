export const generalItemSettingDefaultData = {
    addby: 'basic',
    cess: false,
    unithierarchy: false,
}

export const itemBarcoceDefaultData = {
    isenabled: true,
    barcodelen: 5,
    barcodechar: 'num',
}

export const pointsItemSettingDefaultData = {
    customerPoint: {
        isenabled: false,
        // pointby: '',
        point: '', //for every 100 rupee how many point are you giving?
    },
    salesManPoint: {
        isenabled: false,
        // pointby: '',
        point: '',
    },
    referalPoint: {
        isenabled: false,
        // pointby: '',
        point: '',
    },
}

export const stockItemSettingDefaultData = {
    lowstock: 10,
    itemwisealert: true,
}

export const defaultItemSettings = {
    itemGeneralSetting: generalItemSettingDefaultData,
    itemBarcodeSetting: itemBarcoceDefaultData,
    pointsSetting: pointsItemSettingDefaultData,
    stockSetting: stockItemSettingDefaultData,
}